import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import TeamRoll from "../components/TeamRoll";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import Content, { HTMLContent } from "../components/Content";

import "./equipe-index.scss";

export const EquipeIndexTemplate = ({
  image,
  title,
  content,
  contentComponent,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <div className="EquipeIndex">
      <div className="Layout__header">
        {image && (
          <PreviewCompatibleImage
            className="EquipeIndex__picture"
            imageInfo={{
              image: image,
              alt: `Header banner ${title}`,
            }}
          />
        )}
        <h1>{title || "Équipe"}</h1>
      </div>
      <div className="Layout__body">
        <PostContent
          className="Markdown EquipeIndex__description"
          content={content}
        />
        <TeamRoll />
      </div>
    </div>
  );
};

const EquipeIndexPage = ({ data = {} }) => {
  const { markdownRemark } = data || {};
  const { frontmatter, html } = markdownRemark || {};
  const { image, title } = frontmatter || {};
  return (
    <Layout>
      <EquipeIndexTemplate
        content={html}
        contentComponent={HTMLContent}
        image={image}
        title={title}
      />
    </Layout>
  );
};

EquipeIndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default EquipeIndexPage;

export const pageQuery = graphql`
  query EquipeIndexPage {
    markdownRemark(frontmatter: { templateKey: { eq: "equipe-index" } }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
