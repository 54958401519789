import React from "react";
import PropTypes from "prop-types";
import { Link, graphql, StaticQuery } from "gatsby";
import PreviewCompatibleImage from "./PreviewCompatibleImage";

import "./TeamRoll.scss";

const TeamRoll = ({ data }) => {
  const { edges: posts } = data.allMarkdownRemark;

  return (
    <div className="TeamRoll">
      <div className="TeamRoll__grid">
        {posts.map(({ node: post }) => {
          const {
            id,
            frontmatter,
            fields: { slug },
          } = post || {};
          const { featuredimage, name, doctolib, role, specialite } =
            frontmatter || {};
          return (
            <div className="TeamRoll__card" key={id}>
              <article className="TeamRoll__cardContent">
                <Link to={slug}>
                  <header className="TeamRoll__header">
                    {featuredimage ? (
                      <PreviewCompatibleImage
                        className="TeamRoll__cardImage"
                        imageInfo={{
                          image: featuredimage,
                          alt: `photo de ${name}`,
                        }}
                      />
                    ) : null}
                  </header>
                </Link>

                <div className="TeamRoll__cardBody">
                  <div className="TeamRoll__postInfos">
                    <Link className="TeamRoll__postTitle" to={slug}>
                      {name}
                    </Link>
                    {role && <div>{role}</div>}
                    {specialite && <div>{specialite}</div>}
                    <Link className="TeamRoll__readPostButton button" to={slug}>
                      En savoir plus →
                    </Link>
                  </div>
                  {doctolib && (
                    <a className="TeamRoll__appointment button" href={doctolib}>
                      Prendre rendez-vous
                    </a>
                  )}
                </div>
              </article>
            </div>
          );
        })}
      </div>
    </div>
  );
};

TeamRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default () => (
  <StaticQuery
    query={graphql`
      query TeamRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "equipe" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                name
                role
                specialite
                doctolib
                templateKey
                date(formatString: "MMMM DD, YYYY")
                featuredpost
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 300, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <TeamRoll data={data} count={count} />}
  />
);
